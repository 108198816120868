<template>
    <v-container>
        <v-img
        v-for="file in fileNames" :key="file"
        :src="'/assets/manual/' + file"
        width="100%"
        contain/>
    </v-container>
</template>

<script>
export default {
    props: ['fileNames'],
}
</script>